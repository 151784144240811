import type { IconName } from '../icon';

interface SocialItem {
  href: string;
  icon: IconName;
}

export const socialItems: SocialItem[] = [
  {
    href: 'skype:live:.cid.3d956ddfa66de186?chat',
    icon: 'social/skype',
  },
  {
    href: 'https://t.me/r_3_za/',
    icon: 'social/telegram',
  },
  {
    href: 'https://api.whatsapp.com/send/?phone=447405681874',
    icon: 'social/whatsapp',
  },
  {
    href: 'mailto:office@ib-games.com',
    icon: 'social/email',
  },
];
